<template>
    <div>
      <invoice-send-type-list></invoice-send-type-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  